import styled from "styled-components";

const HealingStyles = styled.div`

  position: relative;
  z-index: 2;

  #introBand {
    color: #b8d5aa;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.07;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 881px;
    width: 100%;
    line-height: 1.4;
  }
`;
function IntroBand({ children }) {
  return (
    <HealingStyles className="dark-green-bg">
      <section id="introBand" className="scroll-fade-in">
        {children}
      </section>
    </HealingStyles>
  );
}

export default IntroBand;
