import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Hello! My name is Sierra, and I am a therapist deeply rooted in the diverse tapestry of Oregon's landscape. As a first-generation student and native Oregonian, my upbringing straddled the worlds of blue-collar loggers and counterculture hippies, shaping my unique perspective on life's complexities.
        </p>
        <p>
        My academic journey, marked by an undergraduate education at the University of Oregon and a Master's degree from the University of Denver, culminated in the fulfillment of my lifelong dream of becoming a therapist. At the core of my therapeutic approach is epistemic humility, emphasizing a curiosity that recognizes that my educational background does not put me in the position of being the expert in understanding you or your life circumstances. Grounded in a humanistic, biopsychosocial framework, I firmly believe that everyone is doing their best, and maladaptive patterns emerge when we lack effective ways to meet our needs.
        </p>
        <p>
        I am trained comprehensively in EMDR, Internal Family Systems, and Dialectical Behavioral Therapy, with a focus on complex trauma and PTSD.  Central to my practice are the principles of non-judgment and compassion, essential elements in fostering true healing.  I view my role as offering psychoeducation on how life experiences, including trauma, impact cognitive functioning. Through empirically supported interventions, we will work to rewire neural pathways, promoting healthier thinking and disrupting maladaptive patterns. I am genuinely enthusiastic for the opportunity to meet you and join you as you work to create an empowering narrative in the next chapter of your life's story! Thank you for taking the time to read my bio.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
