import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
          Educated at Bowling Green State University, Bowling Green OH, MA, in Counseling. Sofia University, Palo Alto, CA, completed doctoral education, and draft of dissertation in Psychology, with a Transpersonal and ecopsychological focus.
        </p>
        <p>
        Completed dissertation edits, defense, and awarded a doctoral degree with high honors at Akamai University in Hilo, HI (Akamai University is accredited internationally, but not recognized by the Dept of Education in the United States).
        </p>
        <p>
        Juanita has been working in mental health and substance use case management and counseling since 1995. She has worked in a variety of settings from court ordered treatment facilities, to inpatient locked mental health units, community mental health, crisis and stabilization, private practice, college level education, and insurance care management and utilization review.
        </p>
        <p>
        Juanita works primarily with adults, from transitional aged young adults to older adults. Primary modalities are CBT, and mindfulness approaches to therapy, though she prefers an eclectic approach that often combines a variety of tools and approaches that are focused on the client’s needs and what works best for each individual, borrowing from various philosophies and modalities.
        </p>
       
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
