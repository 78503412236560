import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
          Have you ever noticed how sometimes life takes over and you feel like its scheduling your rather than you scheduling life? Notice sometimes how the rhythm of busy and hurriedness in life suddenly becomes the rhythm you meet life and relationships with? Ever feel trapped in the same cycles getting the same results? I use EMDR, DBT, CBT and other tools to work with trauma, life transitions, divorce, chronic stressors, family problems, depression, anxiety, ADHD or other struggles. I offer a free consultation to discuss the ways we can collaborate discovering healing and insight.        
        </p>
        <h2>Approach</h2>
        <p>
          I work with a holistic approach addressing trauma and various issues that manifest from chronic stressors throughout lived experiences. My strength-based approach will meet you where you are as an individual, couple and family. I utilize various tools from DBT, CBT, EMDR and expressive interventions to work collaborative and find a path to healing.        </p>
        <h2>Summary</h2>
        <p>
          It can be hard to open up and share the difficulties of life with another person especially if you work in the helping profession. I bring a first responder lived experience and welcome first responders and their families. Let's take the first step together to discover what is possible for you and your future self.        
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
