import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I hold a master’s degree in Mental Health Counseling from Oregon State University (2006) and am a Licensed Professional Counselor. I am also a board approved clinical supervisor for registered associates.
        </p>
        <p>
        Over my 17-year career I have worked in a variety of settings providing therapy to people of all ages and from all walks of life, including residential care, after-hours crisis emergency response, local non-profits, private practice, and at a community college.
        </p>
        <p>
        I am invested in the lifelong learning process of how race and identity impact wellness and mental health. I am biracial and identify as a cis-gender woman and I am aware of white body supremacy and the privileges that this has afforded me. I strive to be an ally for folks with marginalized identities and my goal is to provide affirming, safe and supportive care. This is the lens from which I operate in the world, and as a therapist and supervisor.
        </p>
        <p>
        I have focused much of my therapeutic work on trauma recovery, sexual assault, and mood and anxiety issues. I am trained in S.A.F.E (Somatic and Attachment Focused EMDR), a type of therapy that has shown profound effectiveness in healing from emotional distress caused by traumatic life experiences. I bring warmth, compassion, and a person-centered approach to counseling sessions, and I operate from a trauma informed and humanistic lens. I am skilled at holding space for difficult stories, while also compassionately encouraging, challenging and supporting you to stretch and grow on your healing journey.
        </p>
        <p>
        As a supervisor I provide a patient, warm, and attentive but direct approach. I had amazing supervisors on my journey to licensure and I want to give back and offer a supportive learning experience for my supervisees. I am invested in your learning and growth and am excited and honored to be a part of your formation as a clinician.
        </p>
       

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
