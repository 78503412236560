import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Hello and welcome! Making the decision to start therapy and to work on yourself is a courageous one. I appreciate having the opportunity to walk alongside you on this journey of self-discovery. I pride myself on providing an open, compassionate, and non-judgmental space for you to learn what is and is not working for you, to find your voice, and to own your truth.
        </p>
        <p>
        Life can be hard, and we all develop ways of coping through tough times. Sometimes those ways of coping are no longer effective for us. I am here to help you explore patterns that may not be effective for you, and to teach you new ways of coping with life stressors. We will explore how the stories you developed throughout your life impact how you see and engage with the world around you, and we will challenge any false beliefs you may hold so you may find your voice and own your truth.
        </p>
        <p>
        I am a licensed professional counselor (LPC) and certified alcohol and drug counselor (CADCI). I have experience working with individuals who have struggled with anxiety, depression, trauma, communication difficulties, low self-worth, and addiction. I utilize an eclectic approach in therapy, meaning I will meet you where you are at and pull from different therapeutic approaches to best help you on your healing journey. I have experience with Acceptance and Commitment Therapy (ACT), Cognitive Behavioral Therapy (CBT), Motivational Interviewing (MI), and Dialectical Behavior Therapy (DBT). Thank you for taking the time to read my bio! I look forward to getting to know more about you!
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
