import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I specialize in depression, anxiety, men's issues, and high risk clients, and am also comfortable treating a wide range of presenting issues aside from those. I take an eclectic, client-centered, evidence-based approach that focuses on combining the best available research with your individual preferences and learning/interaction styles. Our time in therapy will focus on teaching you effective skills to change the way that you relate to yourself, your emotions, and your thoughts, while also exploring and validating the reasons that you developed your current patterns in the first place.
        </p>
        <p>
        I pride myself in my deep sense of empathy, and have been told often that I have a calming presence. I have over six years experience in inpatient facilities, often treating clients at their most difficult moment of crisis. I won't ever judge you for difficult thoughts or feelings, and I'll use my experience to guide you through those moments safely.
        </p>
        <p>
        I believe strongly in the power of counseling to change lives: I'm in this field because I've experienced it firsthand. I also know that reaching out for help can be scary and difficult, especially when things are hard. You're already making progress towards change by starting this search and being on here! I would love to help you continue that journey.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
