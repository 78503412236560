import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I believe miracles can occur in people’s lives when they feel heard, seen, validated, and empathized with. As a mental health provider, I strive to meet each individual where they are and through a trauma focused lens work to develop a strong therapeutic alliance, help facilitate identification of the root causes of emotional distress, collaborate on developing healthy coping mechanisms and assist in finding those things that spark joy in a person’s life. I am dedicated to creating a space that can cultivate hope and healing, where an individual can feel empowered to find their authentic and best self.
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
