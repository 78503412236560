import React, { useState } from "react";
import styled from "styled-components";

const DropdownWrap = styled.div`
  .dropdown-container {
    width: 240px;
    background: #f9f9f9;
    border-radius: 8px;
    border: 2px solid #2f3630;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 54px; /* Ensure the height matches the original select */
  }

  .dropdown-header {
    padding-left: 20px;
    width: 100%;
    height: 100%; /* Use full height of the container */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 700;
    position: relative; /* Needed for pseudo-elements */
    text-transform: capitalize;
    z-index: 1;
  }

  /* Before pseudo-element for the vertical line */
  .dropdown-header::before {
    content: "";
    pointer-events: none;
    height: 35px;
    width: 2px;
    background: #2f3630;
    position: absolute;
    right: 41px;
    top: calc(50% - 17.5px); /* Center vertically */
  }

  /* After pseudo-element for the custom arrow */
  .dropdown-header::after {
    content: url("images/dropdown-arrow.svg"); /* Path to your arrow image */
    pointer-events: none;
    position: absolute;
    top: calc(50% - 14px);
    right: 13px;
  }

  .dropdown-body {
    position: absolute;
    border-radius: 8px;
    top: calc(100% + 7px);
    left: 0;
    width: 100%;
    background: #eaf3e6;
    padding: 10px 7px;
  }

  .dropdown-item {
    padding: 2px 27px;
    border-radius: 5px;
    cursor: pointer;
    color: #5e8a64;
    position: relative;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.55;

    &:hover {
      background: #dbebd4;

      &::after {
        content: url("images/select-arrow.svg");
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

const Dropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => setIsOpen(!isOpen);
  const handleSelectOption = (optionValue) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <DropdownWrap>
      <div className="dropdown-container" onClick={handleToggleDropdown}>
        <div className="dropdown-header">{value || "Select an option"}</div>
        {isOpen && (
          <div className="dropdown-body">
            {options.map((option) => (
              <div
                key={option.value}
                className="dropdown-item"
                onClick={() => handleSelectOption(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </DropdownWrap>
  );
};

export default Dropdown;
