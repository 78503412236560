import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I have a passion for providing high quality therapy and helping others to achieve their goals in life. My approach involves client-centered, strengths based, and evidence based treatment. I primarily utilize CBT, DBT, and Motivational Interviewing. I have been trained in other modalities as well and believe in personalizing the approach depending on what works best for you. I believe that therapy is a collaborative process. You are the expert on your own experience and I am here to work with you to achieve whatever you want most out of life. I have many years of experience working with a wide variety of people from different ages, cultures and backgrounds. I have worked with individuals with anxiety, depression, mania, trauma, psychosis, grief, substance use, autism, and ADHD. I recognize the courage it takes to be vulnerable and seek therapy. I consider it an honor and a privilege to be with you in these challenging times. I look forward to meeting and working with you!
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
