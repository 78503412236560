import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ProgressBarWrap = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
  border-radius: 86px;
  background: rgba(124, 169, 130, 0.25);
`;

const Bar = styled.div`
  border-radius: 8px;
  background: linear-gradient(264deg, #b8d5aa -12.32%, #5e8a64 93.81%);
  height: 100%;
  position: absolute;
  left: 0;
  transition: width 0.5s ease; // Adjust the transition time as needed
`;

const ProgressBar = ({ percent }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(percent);
  }, [percent]);

  return (
    <ProgressBarWrap>
      <Bar style={{ width: `${width}%` }}></Bar>
    </ProgressBarWrap>
  );
};

export default ProgressBar;
