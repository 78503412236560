import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I utilize a client-centered approach to counseling, working collaboratively with you to identify your personal goals for growth and healing. I believe that all individuals have the innate capacity for positive transformation in their lives. I have been trained in a variety of treatment modalities and will work with you to determine the approach that will be most helpful for you. The counseling process offers a healing opportunity to discuss your thoughts, feelings, and current life circumstances in a safe and non-judgmental setting in order to find greater self-awareness and learn new skills to support your growth and healing. I have over twenty years of experience in the mental health field. I have worked with individuals with trauma, psychosis, anxiety, depression, addiction, relationship issues, existential issues, and various life changes.  I have worked with many people from diverse backgrounds and am highly invested in cultivating a caring, trusting, and supportive relationship that is so essential for the therapeutic process to be beneficial. 
        </p>
      
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
