import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
          I have worked in various positions within the community mental health
          field for over eight years. I look forward to a long career where my
          community members, especially people of color, veterans, women, and
          individuals needing a cheerleader and advocate in their corner, can
          receive the care that they deserve.
        </p>
        <p>
          I believe in creating a safe space for individuals to be vulnerable,
          open, and honest. As we work together, I will use my skills and voice
          to empower you to share your narrative. Therapy is a journey and I
          encourage you to take things one day at a time and know that you are
          worthy and powerful beyond measure. I want to understand you as a
          person and hope that you will soon be able to see yourself for who you
          are and not by what society or your diagnosis tells you. Seeking
          mental health treatment is a big step and I want to celebrate this
          journey with you.
        </p>
        <p>
          Therapy does not have to happen sitting still, I encourage you to move
          your body as needed and welcome the playfulness and joy that comes
          with self-acceptance and understanding. Music can also be a powerful
          tool so if there is a song that resonates with you, I would love to
          hear about it.
        </p>

        <h2>Areas of Expertise</h2>

        <ul>
          <li>
            Grief and Loss – Everyone grieves and experiences loss in their own
            unique way. I will create a space where you can honor the memories,
            feel your emotions, discover your strength, and develop healthy
            coping strategies. We will take things at your own pace and know
            that I will not force you to go faster than you are ready or
            comfortable with.
          </li>
          <li>
            Trauma Healing – Sometimes it can be hard to connect with one’s body
            after a traumatic experience, so I like to use Somatic Interventions
            and Mindfulness to help rebuild that connection. The courage to heal
            comes from within and as you find your voice to tell your story and
            to take ownership of your experience, I will be here to support you
            in your healing journey.
          </li>
          <li>
            Anxiety, Depression, and Stress – Our bodies are great at letting us
            know when something is not right. I will work with you to recognize
            what your body is telling you and guide you in listening and
            understanding how to tell when you are feeling anxious, depressed,
            or stressed. Together we will work on giving yourself permission to
            be present with your body.
          </li>
          <li>
            Mood Disorders – It’s hard to feel better when you are not even sure
            why you feel the way you do. I will help you understand how to
            identify the patterns, manage your symptoms, and find healthy ways
            to cope. You are not your diagnosis and together we can create a
            healthy routine so that you can take charge of your life.
          </li>
        </ul>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
