import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I strive to create a warm and welcoming space for you. I believe that healing and growth are possible for everyone, and that counseling can help you find strength and empowerment, stability, meaning and connection in your life, and provide a nonjudgmental space to process and work through issues. My approach is strengths-based, holistic, and collaborative. 
        </p>
        <p>
        I have worked with people from many backgrounds including those who have experienced trauma, psychosis, anxiety, depression, relationship issues, grief, life changes, and more. I have also worked with people of diverse cultural backgrounds including people from the LGBTQ+ community. I have worked with people of a variety of ages, from children to older adults. I use a person-centered approach and draw from other practices such as internal family systems, somatic therapy, mindfulness, art therapy, cognitive behavioral therapy, dialectical behavioral therapy, acceptance and commitment therapy, and more. I am excited to get to know you and walk with you on your healing journey!
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
