import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Hello! I’m Bridget. I am a graduate of Purdue University Northwest’s Clinical Mental Health Counseling program. I have experience working with a wide population, but especially with women and adolescents. I have worked with many types of disorders and situations including anxiety, depression, self-esteem, and LGBTQ+ issues. My therapeutic approaches include CBT, strengths-based, and mindfulness. Expressive arts therapy has also become a large part of my training, and one that I really enjoy. Lastly, I am a proud member of both the disability and LGBTQ+ communities.
        </p>
        <p>
        I believe that counseling is not a one-size-fits-all process. Every person has potential to become the best version of themselves; your counselor is here to help you do that! I also have an interest in combining therapeutic approaches with holistic health to create an experience that is unique to every client. I am always open to feedback to ensure that you are getting what you want out of the session - no problem is too big or small! My goal is to make my clients feel comfortable and adapt to what they see fit.
        </p>
       
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
