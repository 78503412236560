import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
            I am passionate about helping others in any way I can and I enjoy talking to and meeting new people! I have over 10 years of experience in different medical settings - from working in a toxicology and pharmacogenetics laboratory in Orange County, CA to working at Willamette Valley Cancer Institute in Eugene, OR. I value doing all I can to go above and beyond to make patients feel seen, comfortable, and to get their questions answered.
        </p>
        <p>
            I have moved over 20 times in a 20 year span, but have found that Oregon is an incredible place to call home. In my free time, I enjoy spending my time outside with my husband and our two dogs, Cora and Luna. I love to workout and am constantly finding myself doing research on nutritional information and new ways to grow in my journey of becoming the healthiest version of myself.
        </p>
        <p>
            I look forward to talking to you soon!            
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
