import React from "react";
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import EmergencyYes from "./EmergencyYes";
import Location from "./Location";

const IsEmergencyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .button-set {
    display: flex;
    padding: 20px;
    gap: 20px;
    button {
      margin-bottom: 10px;
    }
  }
`;

const IsEmergencyStep = () => {
  const { nextStep } = useProgress();

  const yesClicked = () => {
    nextStep(<EmergencyYes />, 100)
  };

  const noClicked = () => {
    nextStep(<Location />, 20)
  };

  return (
    <IsEmergencyWrap>
      <h2>Is this an emergency?</h2>
      <div className="button-set">
        <ButtonPlain onClick={yesClicked}>Yes</ButtonPlain>
        <ButtonPlain onClick={noClicked} dark>
          No
        </ButtonPlain>
      </div>
    </IsEmergencyWrap>
  );
};

export default IsEmergencyStep;
