import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
            Patrick has been a counselor since 2014.  He has years of helpful and enlightened experience as a practicing lawyer for 22 years and as a military pilot with 21 years of service, including 2 combat tours in Vietnam and Bosnia.  He has clearly learned valuable lessors that help begin each new day with an open and eager anticipation of exciting things to come.  <br/><br/>Patrick has the tools, education, training, and experience to help others achieve their hopes and dreams and become ready, empowered, and on a positive path to change, to grow, and to become the person each has always wanted to be.        
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
