import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
        <h2>Introduction</h2>
        <p>
        I believe in an individual’s power to continually transform and grow. Everyone has moments where they want to pause, reflect on their story, and what they want moving forward. You have made a courageous choice to enter counseling and I am excited to walk beside you on this part of your mental health journey! I work with adult individuals experiencing a variety of presenting concerns: life transitions, depression, anxiety, grief and loss, among many other things life can throw at us. 
        </p>
        <p>
        While I do draw from several major theories in counseling, including Cognitive Behavioral Therapy, Solution Focused Brief Therapy, and Strengths Based Therapy, I primarily ground my practice in a client-centered approach. This means that you will be in the driver’s seat during the therapy process, and we’ll learn together how therapy can best serve you.
        </p>
        <p>
        Education: I hold a Masters Degree in Clinical Mental Health Counseling from George Fox University, a Bachelor’s Degree in Psychology from Western Oregon University, and also have experience in personal support and caregiver roles.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
