import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am an advocate of change, growth, and self-fulfillment. Transitions in life are difficult, whether it is changing jobs, relationships, environments, or simply aging and rediscovering yourself. I can help you to define yourself and the how you fit within your own life by finding meaning in the small things that go by unnoticed or by helping to restructure and find balance for a specific goal. I am flexible in my approach so that I can meet you where you are and help you move toward healing, happiness, or whatever goals you may have.
        </p>
       
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
