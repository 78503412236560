import React, { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";
import LocationsData from "../data/locations-data.json";
import LocationPopup from "../components/LocationPopup";

const Hero = styled.div`
  nav {
    z-index: 3;
    position: relative;
  }

  #hero {
    padding: 130px 30px 120px;
    position: relative;
  }

  @media screen and (max-width: 970px) {
    #hero {
      padding: 50px 30px 0;
      margin-bottom: -20px;
      position: relative;
    }
  }

  #hero #hero-graphic {
    position: absolute;
    right: 166px;
    bottom: -100px;
    width: 490.15px;
  }

  @media screen and (max-width: 1200px) {
    #hero #hero-graphic {
      width: 500px;
      right: 53px;
    }
  }

  @media screen and (max-width: 970px) {
    #hero #hero-graphic {
      position: relative;
      right: unset;
      bottom: 0;
      max-width: 600px;
      width: 90%;
      display: block;
      margin: 0 auto -120px;
    }
  }

  @media screen and (max-width: 700px) {
    #hero #hero-graphic {
      margin: 0 auto -11vw;
    }
  }

  #hero .wrap {
    width: 1184px;
    margin: auto;
    position: relative;
    z-index: 2;
  }

  #hero .inner-wrap {
    flex-direction: column;
    display: flex;
    width: 474px;
  }

  @media screen and (max-width: 970px) {
    #hero .inner-wrap {
      align-items: center;
      text-align: center;
      margin: 0 auto 20px;
    }
  }

  #hero .inner-wrap p {
    margin: 30px 0;
  }

  @media screen and (max-width: 1050px) {
    #hero .inner-wrap p {
      width: 400px;
      max-width: 100%;
    }
  }
`;

const ListingSection = styled.section`
  padding-top: 60px;
`;

const LocationsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

const ProfileCard = styled.div`
  width: 300px;
  text-align: center;
  margin: 30px 40px;
  cursor: pointer;
  transition: transform 0.3s ease;

  @media screen and (max-width: 1200px) {
    margin: 15px;
  }

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    opacity: 0.5;
  }
`;

const LocationImageWrap = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: scale 0.3s ease;
  box-shadow: 0px 4px 4px 0px rgba(47, 54, 48, 0.20);


  @media screen and (max-width: 800px) {
    width: 300px; /* Maintain width */
    height: auto; /* Allow height to adjust */
  }
`;

const LocationImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  
  scale: 1;


  @media screen and (max-width: 800px) {
    width: auto; /* Allow width to adjust */
    height: 300px; /* Maintain height */
  }
`;

const PopupImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  transition: scale 0.3s ease;
  scale: 1;

  ${LocationImageWrap}:hover & {
    scale: 1.05;
  }
`;

const City = styled.div`
  font-size: 22px;
  line-height: 1.27;
  font-weight: bold;
  margin-top: 10px;
`;

const State = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 1.27;
`;

const Divider = styled.div`
  width: 33%;
  height: 2px;
  background-color: white;
  margin: 5px 0;
  display: none;

  ${LocationImageWrap}:hover & {
    display: block; // Expand to 75% height on hover
  }
`;

const Address = styled.div`
  color: white;
  font-size: 14px;
  line-height: 1.27;
  text-align: center;
  margin-top: 5px;
  display: none;

  ${LocationImageWrap}:hover & {
    display: block; 
  }
`;

const LocationPin=styled.img`
  position:fixed;
  width: 80px;
  bottom:0;
  right:.5px;
  opacity: 0;  /* Start fully transparent */
  visibility: hidden;  /* Make it hidden initially */
  transition: opacity 0.5s ease-in-out;  /* Smooth transition */

  ${LocationImageWrap}:hover & {
    opacity: 1;  /* Fully opaque on hover */
    visibility: visible;  /* Make it visible on hover */
    pointer-events: all;  /* Ensure it can receive pointer events */
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%; // Bottom 40%
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5E8A64;
  border-top: 2px solid white; // White line separating the sections
  box-sizing: border-box;
  transition: height 0.3s ease;

  ${LocationImageWrap}:hover & {
    height: 75%; // Expand to 75% height on hover
  }
`;

const PopupLayout = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: 1021px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 30px;

  @media screen and (max-height: 1100px) {
    max-height: 80vh;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    max-height: unset;
  }
`;

const PopupLeft = styled.div`
  width: 300px;
  text-align: left;

  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

const PopupRight = styled.div`
    flex-grow: 1; /* Allow this div to grow and fill available space */
    display: flex;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    position: relative;
    padding-left: 40px;

    @media screen and (max-width: 850px) {
        width: 100%;
        padding: 20px 0 0;
    }
`;

const PopupLocation = styled.div`
  color: black;
  font-size: 24px;
  line-height: 1.27;
  text-align: left;
  margin-top: 5px;
  font-weight: bold;
`;

const PopupAddress = styled.div`
    color: black;
    font-size: 22px;
    line-height: 1.27;
    text-align: left;
    margin-top: 2px;
    margin-bottom: 2px;
`;

const PopupAddressWrap = styled.div`
  padding: 5px;
`;

const LocationInfo = styled.div`
  color: black;
  font-size: 18px;
  line-height: 1.27;
  text-align: left;
  margin-top: 10px;
`;

const MapImage = styled.div`
    width: 100%;
    height: auto;
    border: 2px solid black;
    position: relative; /* Ensure the iframe can be positioned absolutely within */
    overflow: hidden; /* Hide any overflow from the iframe */
    border-radius: 10px; /* Optional: add border radius if needed */
`;

const PopupContent = ({ location }) => (
    <PopupLayout>
        <PopupLeft>
            <PopupLocation>{location.city + " " + location.state}</PopupLocation>
            <PopupAddressWrap>
                <PopupAddress>{location.addressLine1}</PopupAddress>
                <PopupAddress>{location.addressLine2}</PopupAddress>
            </PopupAddressWrap>
            <LocationImageWrap>
                <PopupImage
                    src={`/images/locations/${location.photo}`}
                    alt={location.city}
                />
            </LocationImageWrap>
            <LocationInfo>
                {location.info}
            </LocationInfo>
        </PopupLeft>
        <PopupRight>
            <MapImage>
                <iframe 
                    src={`${location.map}`}
                    title={location.city} 
                    allowfullscreen="" 
                    loading="lazy"
                    width="100%" 
                    height="500px"
                    style={{ position: 'relative', top: 0, left: 0, border: 0 }}
                />
            </MapImage>
        </PopupRight>
    </PopupLayout>
);

function Locations() {
  //popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <FadeInOnScroll>
      <main>
        {isPopupVisible && (
          <LocationPopup onClose={handleClose}>
            <PopupContent location={selectedLocation} /> {/* Pass location data as props */}
          </LocationPopup>
        )}
        <Hero className="green-bg">
          <Nav />

          <section id="hero">
            <div className="wrap">
              <div className="inner-wrap">
                <h1>Locations</h1>
                <p>
                  We offer in-person and telehealth counseling. Click any of the location cards below to get driving directions.
                </p>
              </div>
            </div>
            <img
              id="hero-graphic"
              className="scroll-fade-in"
              src="images/locations/green-tree.png"
              alt="bonzai tree"
            />
          </section>
        </Hero>
        <IntroBand>
          "Healing comes when we choose to walk away from darkness and move
          towards a brighter light."
        </IntroBand>
        <ListingSection>
          <LocationsSection>
            {LocationsData.map((location, index) => {
              const CardContent = () => (
                <>
                  <LocationImageWrap>
                    <LocationImage
                      src={`/images/locations/${location.photo}`}
                      alt={location.city}
                    />
                    <InfoOverlay>
                      <City>{location.city}</City>
                      <State>{location.state}</State>
                      <Divider />
                      <Address>{location.addressLine1}</Address>
                      <Address>{location.addressLine2}</Address>
                      <LocationPin 
                        src={`/images/locations/location-pin.png`}
                        alt="location pin"
                      />
                    </InfoOverlay>
                  </LocationImageWrap>
                </>
              );

              const profileCard = (
                <ProfileCard
                  className={"listing-card"}
                  key={index}
                  onClick={() => {
                    setSelectedLocation(location);
                    setIsPopupVisible(true);
                  }}
                >
                  <CardContent />
                </ProfileCard>
              );

              return profileCard;
            })}
          </LocationsSection>
          <div className="gap"></div>
        </ListingSection>
        <CallToAction />
      </main>
      <Footer />
    </FadeInOnScroll>
  );
}

export default Locations;
