import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Congrats on making your mental health a priority! I see counseling as an opportunity for self-exploration and growth. I am passionate about helping my clients gain a better understanding of their story and how their previous experiences in life are influencing their present. I strive to create a safe environment for my clients, one in which they feel heard and understood with no judgment. I have had the pleasure of working with individuals from all different ages, cultures, backgrounds, and cognitive/developmental levels.
        </p>
        <p>
        I am here to meet you where you are at, and help you meet your goals. I value seeing every client as the expert of their own experience. I believe we all have the ability to feel empowered and secure in our lives and it is my goal to support you in taking the steps toward lasting change.
        </p>
        <p>
        I am experienced in Cognitive Behavior Therapy (CBT), Dialectical Behavioral Therapy (DBT), Solution Focused Therapy, Compassion Focused Therapy, Mindfulness-based Cognitive Therapy, and more. I have worked with individuals who have experienced trauma, mood disorders, anxiety, depression, and other mental health concerns. I have experience with couples, siblings, and families.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
