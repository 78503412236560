import styled, { keyframes } from "styled-components";

const fadeInBlur = keyframes`
  from {
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    backdrop-filter: blur(px);
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  overflow-y: auto;
  animation: ${fadeInBlur} 0.3s ease-in-out forwards;
  @media screen and (max-width: 800px) {
    padding-top: 50px;
    align-items: flex-start;
  }
`;

export default Overlay;
