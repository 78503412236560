import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Since entering the counseling field in 2018, I have worked with a range of clients in a variety of settings. I completed my MA in counseling in 2021 through Lewis and Clark in Portland, Oregon. I specialize in depression, anxiety, grief, and am also comfortable treating a variety of presenting issues aside from those listed. I enjoy working with teenagers and adults and am committed to working with individuals to achieve their goals while engaged in counseling.
        </p>
        <p>
        I believe that you are the expert of your own experience. I hope to create a space where you feel safe, cared for, respected, and welcomed to explore deeper layers of that experience. My theoretical orientation is eclectically rooted in a client-centered and evidence-based approach while being trauma informed. Additionally, my approach is strength-based in nature and focuses on your own strengths and personal abilities to persevere in the face of challenges. Through our collaboration, we will work to explore your concerns and to explore possible changes you can implement in your life while acknowledging sources of strength and resiliency.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
