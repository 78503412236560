import React, { useState } from "react";
import styled from "styled-components";
import { useProgress } from "../../contexts/ProgressContext";
import ThankYou from "./ThankYou"; 

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    padding-bottom:  5px;
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const IntakeQuestionnaireWrap = styled.div`
  text-align: center;

  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }

  h2 {
    margin-top: 112px;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      margin: 0 0 30px;
    }
  }

  h3 {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.4;
  }

  p {
    width: 920px;
    max-width: 100%;
  }

  .call-section,
  .form-section {
    margin: 30px 0 20px;
  }

  .call-section {
    padding-bottom: 40px;
    @media screen and (max-width: 700px) {
      padding-top: 20px;
    }
    .call-number {
      color: #5e8a64;
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.4;
      margin: 10px 0;
    }
  }

  .schedule-call {
    margin: 60px 0 40px;
  }

  .form-section form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(50px, auto);
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .submit-wrap {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    grid-column: span 2;
    @media screen and (max-width: 700px) {
      grid-column: unset;
    }
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #bbb;
    font-size: 18px;
    font-weight: bold;

    &:before,
    &:after {
      content: "";
      flex: 1;
      border-bottom: 2px solid #5e8a64;
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid black;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    box-sizing: border-box;
  }

  input:focus,
  select:focus {
    outline: none; /* Remove the outline */
    border-bottom: 2px solid black; /* Keep the border consistent */
  }

  input::-webkit-input-placeholder {
    color: #5e8a64;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #5e8a64;
    opacity: 1; /* Override Firefox's default opacity */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #5e8a64;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #5e8a64;
  }

  input::placeholder {
    /* Most modern browsers */
    color: #5e8a64;
  }

  select {
    width: 100%;
    color: #5e8a64; /* This will color the arrow and the selected option */
  }

  select option {
    color: black; 
  }
`;

const IntakeQuestionnaire = () => {
  const { nextStep, location, payingBy } = useProgress();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    availableDays: "",
    preferredTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function buildEncodedEmailURL(formData) {
    // Base URL and the recipient's email address
    const baseURL = "https://sendemaildatetimes-ewacs772ua-uc.a.run.app?";
    const recipientEmail = "info@zendo.health"; 
  
    // Map the JSON keys to the expected URL parameters
    const queryParams = {
      to: recipientEmail,
      first: formData.firstName,
      last: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      location: formData.location,
      paying: formData.payingBy,
      days: formData.availableDays || null,
      time: formData.preferredTimeWindow || null
    };
  
    // Filter out null or empty values
    const filteredParams = Object.keys(queryParams).reduce((acc, key) => {
      if (queryParams[key] !== null && queryParams[key] !== '') {
        acc[key] = queryParams[key];
      }
      return acc;
    }, {});
  
    // Build query string from the filtered params
    let queryString = Object.keys(filteredParams).map((key) => {
      // Assuming all values are strings; if not, they need to be converted to strings
      let value = String(filteredParams[key]);
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }).join('&');
  
    // Return the full URL
    return baseURL + queryString;
  }

  async function sendEmail(encodedURL) {
    try {
      const response = await fetch(encodedURL);
  
      // Check if the request was successful
      if (response.ok) {
        // If the response status code is 200-299
        console.log("Email sent successfully!");
        return { status: "success", statusCode: response.status };
      } else {
        // If the response status code is not in the 200 range
        console.error("Failed to send email. Status:", response.status);
        return { status: "fail", statusCode: response.status };
      }
    } catch (error) {
      // If there is an error during fetch
      console.error("Error while sending email:", error);
      return { status: "error", error: error };
    }
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Process form submission here. On success:

    const finalFormData = { ...formData, location, payingBy };

    const emailUrl = buildEncodedEmailURL(finalFormData);

    const result = await sendEmail(emailUrl);

    nextStep(<ThankYou />, 100);
  };

  return (
    <IntakeQuestionnaireWrap>
      <h2>Intake questionnaire</h2>
      <p>
        To start your journey with us, we'll begin with a brief, 15-minute
        intake questionnaire over the phone conducted by one of our dedicated
        Client Specialists. This conversation will cover your current needs and
        any relevant past history to ensure we match you with the counselor best
        suited to you. Typically, you'll have the opportunity to schedule your
        first appointment during this call, with most clients seeing a counselor
        within one week.
      </p>
      <div className="call-section">
        <h3>
          <b>Call now</b>
        </h3>
        <ArrowLink className="call-number open-sans-font" href="tel:+15418180009">1 (541) 818-0009</ArrowLink>
        <p>
          <b>We're available weekdays</b> from{" "}
          <b className="open-sans-font">9am</b> to{" "}
          <b className="open-sans-font">5pm</b> PT
        </p>
      </div>
      <div className="separator">or</div>
      <div className="form-section">
        <h3 className="schedule-call">Schedule a callback</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First name"
            required
          />

          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last name"
            required
          />

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />

          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            required
          />

          <select
            className="form-dropdown"
            name="availableDays"
            value={formData.availableDays}
            onChange={handleChange}
            // required
          >
            <option value="">Select Availability</option>
            <option value="weekdays">Weekdays</option>
            <option value="weekends">Weekends</option>
            <option value="anytime">Anytime</option>
          </select>

          <select
            className="form-dropdown"
            name="preferredTimeWindow"
            value={formData.preferredTimeWindow}
            onChange={handleChange}
            // required
          >
            <option value="">Select Preferred Time Window</option>
            <option value="Morning">Morning</option>
            <option value="Afternoon">Afternoon</option>
            <option value="Evening">Evening</option>
          </select>
          <div className="submit-wrap">
            <button type="submit" className="submit-button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </IntakeQuestionnaireWrap>
  );
};

export default IntakeQuestionnaire;
