import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Hi, I'm Savreet! I obtained my master’s degree in Marriage and Family Counseling from George Fox University. Throughout my career, I've focused on helping individuals from various backgrounds, especially children and families, dealing with challenges like anxiety, depression, ADHD, and behavioral concerns. Therapeutic techniques that I integrate in my practice include DBT, CBT, strength-based, narrative, and mindfulness.
        </p>
        <p>
        As a mental health professional, my goal is to create a secure and nurturing environment where my clients feel empowered to express themselves. I'm dedicated to guiding my clients on their journey no matter what that looks like while offering unwavering support and guidance. 
        </p>
        <p>
        I'm excited to continue supporting you on your therapeutic journey! 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
