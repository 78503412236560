import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I believe that people are strong and resilient with vast internal resources for transformation and healing. This is the foundation of my counseling practice. I see my role as creating conditions that increase access to those resources.  
        </p>
        <h2>
          Areas of Focus:
        </h2>
        <p>Trauma</p>
        <p>Anxiety</p>
        <p>Depression</p>
        <p>Major Life transitions</p>
        <p>
        I am trained in and have experience using Eye Movement Desensitization and Reprocessing (EMDR). I use EMDR with most individuals I meet with, especially for Trauma Resolution. 
        </p>
        <p>
        Other interventions I use are Mindfulness and Guided Imagery, and Cognitive Behavioral Therapy. My training and experience focus on evidence-based, goal-oriented interventions with the intention of clearing what has been keeping you stuck.
        </p>
        <p>
        I will offer new, different, and more effective ways of thinking about things that will increase wellness and encourage Individuals to move toward living a more genuine, satisfying life.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
