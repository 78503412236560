import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        “One does not become enlightened by imagining figures of light but by making the darkness conscious”
        <br></br>
        -C.G. Jung
        </p>
        <p>
        Are you currently experiencing a “dark night of the soul” or are you feeling overwhelmed by a life transition? I’m here to offer a safe space and caring relationship in which to heal, work through, and overcome these trials. I’ve been working in the mental health field since 2018 and more recently received my Masters in Counseling Psychology from Pacifica Graduate Institute with an emphasis in Depth Psychology. I am currently under the supervision of Caitlin O’Dell as I work towards licensure as a Marriage and Family Therapist. I have experience working with a wide variety of people with the utmost cultural sensitivity such as children, teens, and adult individuals as well as couples and families. I have familiarity of working within school settings, in-patient facilities and have worked extensively with members of the LGBTQIA+ community.
        </p>
        <p>
        My areas of expertise are working with those experiencing:
        <br></br>
-Grief and loss
<br></br>
-Trauma and PTSD
<br></br>
-Anxiety
<br></br>
-Depression
<br></br>
-Eating Disorders
<br></br>
-Major Life Transitions such as having a baby, getting married or divorced,

and receiving a medical diagnosis to name a few
        </p>
<p>
I have a unique approach to therapy that blends my training in the psychoanalytic/psychodynamic traditions with relational, existential, contemplative/mindfulness, and family systems modalities. I have experience with

interventions from other modalities as well such as Motivational Interviewing (MI), Dialectical Behavioral Therapy (DBT), Cognitive Processing Therapy (CPT), Internal Family Systems (IFS), Narrative therapy, Violet Oaklander’s Play Therapy, Bibliotherapy and Dreamwork. Ultimately, I believe that healing occurs most within the context of a collaborative relationship in which one may explore emotions, discover strengths and develop coping strategies. Through establishing a healthy, therapeutic relationship, we will take a collaborative approach to support your work toward identified goals that facilitate change and growth.
</p>
<p>
“Tell me, what is it you plan to do with your one wild and precious life?”
<br></br>
-Mary Oliver
</p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
