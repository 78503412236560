import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I believe that all people have the potential to change. Making changes to yourself can be a confusing process that brings up some of the deepest insecurities, weaknesses, and pain. Together we can make space for those tough feelings to surface and we can work collaboratively to discover your strengths to empower you on your healing journey. 
        </p>
        <p>
        I value authentic connections and seek to empower all I come in contact with. As a white woman in the United States, I recognize the social constructions that define our gender roles, power structures, and identities and work to help my clients define their own values, beliefs, identity, and expression for themselves. I provide affirmative counseling to the LGBTQ+ and BIPOC communities and all oppressed people who hold a minority identity. 
        </p>
        <p>
        I am currently studying to become a Licensed Professional Counselor at Adams State University. My life journey is a confluence of creativity, movement, expression, and collaboration. I hold a Master of Fine Arts in Dance from the University of Oregon and have been certified as a practitioner of Structural Integration. I was a dance educator for over 20 years, teaching in studios, high schools, colleges, universities, and in the community. I learned the power of storytelling through movement patterns which expressed and reflected the oneness of human experience. Witnessing the vulnerability that dance embodies, I knew that there was a connection between how our minds work and what our bodies show, which led me to become interested in counseling.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
