import styled from 'styled-components';

const Radio = styled.input`
  font-size: 22px;
  border: ${({ dark }) => dark ? 'none' : '2px solid #2F3630'};
  background: ${({ dark }) => dark ? '#333' : 'white'};
  color: ${({ dark }) => dark ? 'white' : '#2F3630'};
  cursor: pointer;
  outline: none;
  font-weight: ${({ bold }) => bold ? 'bold' : '500'};

  // Ensuring buttons are the same height
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px; // Adjust the height as needed to match the provided image
  // margin: 0 8px;
  margin-right: 8px;
`;

const Label = styled.label`
  // Ensuring labels are the same height
  display: inline-flex;
  align-items: center;
  height: 50px; // Adjust the height as needed to match the provided image
  font-weight: ${({ bold }) => bold ? 'bold' : '500'};
  width: 187px;
`

const RadioButton = ({ name, value, label, currentValue, setter }) => {
  const handleChange = () => setter(value);
  return <Label>
    <Radio type="radio" name={name} value={value} checked={currentValue === value} onChange={handleChange}></Radio>
    {label}
  </Label>
}

export default RadioButton;