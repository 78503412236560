import React, { useState, useEffect } from "react";
import styled from "styled-components";

const FilterDisplayContainer = styled.div`
  display: flex;
  flex-direction: column-reverse; /* To stack boxes and show recent filter on top */
  gap: 5px; /* Adjust as needed */
  position: relative;
  width: 400px;
  @media screen and (max-width: 1050px) {
    height: 108px;
    top: 40px;
    left: -66px;
    pointer-events: none;
  }
`;

const FilterTag = styled.div`
  background: rgba(184, 213, 170, 1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  height: 59px;
  width: 249px;
  position: absolute;
  top: -27px;
  right: 0;
  pointer-events: auto;
  &:nth-of-type(2) {
    margin-right: 20px;
    opacity: 0.5;
    z-index: -1;
  }
  &:nth-of-type(3) {
    margin-right: 40px;
    opacity: 0.4;
    z-index: -2;
  }
`;

const FilterName = styled.span`
  margin-right: 8px;
  text-transform: capitalize;
`;

const RemoveButton = styled.div`
  border: none;
  background: transparent;
  cursor: pointer;
`;

const FilterDisplay = ({ filters, setFilters }) => {
  const filtersArray = Object.entries(filters)
    .filter(([, value]) => value.length > 0) // Filter out empty filter arrays
    .flatMap(([key, values]) => values.map((value) => ({ key, value }))) // Create a flat array of all active filters
    .slice(0, 3); // Limit to displaying only the first 3 filter tags

  const removeFilter = (filterKey, filterValue) => {
    const newFilters = (currentFilters) => {
      // Copy current filters to a new object
      const newFilters = { ...currentFilters };
      // Remove the specific value from the filter array
      newFilters[filterKey] = newFilters[filterKey].filter(
        (value) => value !== filterValue
      );
      return newFilters;
    };
    setFilters(newFilters);
  };

  // Function to handle click on the "X" button of a filter
  const handleRemoveFilter = (filterKey, filterValue) => {
    removeFilter(filterKey, filterValue);
  };
  useEffect(() => {}, [filters]);

  return (
    <FilterDisplayContainer>
      {filtersArray.map((filter, index) => (
        <FilterTag key={`${filter.key}-${index}`}>
          {index === 0 && (
            <>
              <FilterName>{`${filter.value}`}</FilterName>
              <RemoveButton
                onClick={() => handleRemoveFilter(filter.key, filter.value)}>
                <img src="/images/icons/x.svg" alt="Remove" />
              </RemoveButton>
            </>
          )}
        </FilterTag>
      ))}
    </FilterDisplayContainer>
  );
};

export default FilterDisplay;
