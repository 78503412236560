import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
            Chris and his father, Patrick Hyland, started Oregon Directions in 2021. In 2023, Chris transitioned from part-time to full-time and took over the CEO role. Chris is new to the counseling industry, and has worked in technology for over 30 years. He has worked for large corporations (e.g. Microsoft), medium size companies (e.g. Ancestry.com), and small 3-person start-up companies (e.g. Control4). All of these companies have been across a wide range of industries. <br/><br/>Chris is an engineer by trade but has spent the last 20 years in management, and the last 10 years in executive management. He's excited for the opportunity to bring a wide-range of skills and knowlege to this amazing team here at Oregon Directions.        
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
