import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am a dedicated and compassionate counselor specializing in the treatment of trauma. I understand the profound impact that traumatic experiences can have on mental health, and my mission is to provide a safe and supportive space for individuals on their healing journey. Utilizing evidence-based practices, I tailor my approach to meet the unique needs of each client, whether they are dealing with past trauma or navigating current challenges. My goal is to foster resilience and empower clients to build a foundation for lasting healing and growth. If you're ready to take the first step towards a brighter future, I am here to support you in reclaiming your life.
        </p>
        
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
