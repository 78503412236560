import styled from 'styled-components';

const Button = styled.button`
  padding: 10px 20px;
  font-size: 22px;
  border: ${({ dark }) => dark ? 'none' : '2px solid #2F3630'};
  background: ${({ dark }) => dark ? '#333' : 'white'};
  color: ${({ dark }) => dark ? 'white' : '#2F3630'};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  font-weight: ${({ bold }) => bold ? 'bold' : '500'};
  width: 187px;
  &:hover {
    background-color: ${({ dark }) => dark ? '#555' : '#eee'};
    color: ${({ dark }) => dark ? 'white' : 'black'};
  }

  // Ensuring buttons are the same height
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px; // Adjust the height as needed to match the provided image
`;

export default Button;