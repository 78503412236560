import PersonBios from './person-Bios';

function PersonBio({ personName }) {
  const PersonComponent = PersonBios[personName];

  if (!PersonComponent) {
    return <div>Person {personName} not found</div>;
  }

  return <PersonComponent />;
}

export default PersonBio;