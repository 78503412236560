import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I specialize in working with children and families, as well as trauma work, depression and anxiety, chronic illness and chronic pain. However you define your family, we often need ways to better connect and support each other with tools that can be learned inside and out of a therapy, but are only as good as they are useful. I believe the therapeutic relationship is the key piece of recovery, the skills are only as good as the collaboration. I have specialized training in working with children using play therapy, trauma training, working with families as well as individuals who have struggled with depression and anxiety and severe and persistent mental illness.I have a laid back and friendly approach to therapy, while still being goal oriented and purposeful. I am respectful of all values and often work with people who have counseling anxiety, to overcome this and work towards a respectful and peaceful experience.
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
